import React from "react"

import SEO from "../components/seo"

import Products from "../components/products/products"

const IndexPage = () => (
  <>
    <SEO title="Catálogo Armários" />

    <Products />
  </>
)

export default IndexPage
