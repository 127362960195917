import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      afbarmariobaixopreto: file(
        relativePath: { eq: "products/af-baixo-preto.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afbarmariobaixopreto2: file(
        relativePath: { eq: "products/af-baixo-preto2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afbarmariobaixopreto3: file(
        relativePath: { eq: "products/af-baixo-preto3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afbarmariobaixoazulcinza: file(
        relativePath: { eq: "products/af-baixo-azul-cinza.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariovermelhopreto: file(
        relativePath: { eq: "products/af-baixo-vermelho-preto.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetaspreto: file(
        relativePath: { eq: "products/af-3-gavetas-preto.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetaspreto2: file(
        relativePath: { eq: "products/af-3-gavetas-preto2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetaspreto3: file(
        relativePath: { eq: "products/af-3-gavetas-preto3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetaspreto4: file(
        relativePath: { eq: "products/af-3-gavetas-preto4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetaspreto5: file(
        relativePath: { eq: "products/af-3-gavetas-preto5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetascinza: file(
        relativePath: { eq: "products/af-3-gavetas-cinza.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetascinza2: file(
        relativePath: { eq: "products/af-3-gavetas-cinza2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      af3gavetascinza3: file(
        relativePath: { eq: "products/af-3-gavetas-cinza3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afsimples: file(relativePath: { eq: "products/af-simples1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afduplo: file(relativePath: { eq: "products/af-duplo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afduplo2: file(relativePath: { eq: "products/af-duplo2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afduplo3: file(relativePath: { eq: "products/af-duplo3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afduplo4: file(relativePath: { eq: "products/af-duplo4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      afduplo5: file(relativePath: { eq: "products/af-duplo5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.afbarmariobaixopreto.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afbarmariobaixopreto2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afbarmariobaixopreto3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afbarmariobaixoazulcinza.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariovermelhopreto.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário Ferramentas Baixo</h3>

          <ul>
            <li>
              Material na chapa 26. Disponível na chapa 24 e 22 consulte valores
            </li>
            <li>Gavetas rolamento</li>
            <li>Altura: 83 cm </li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 820,<span>00</span>
          </p>
          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.afduplo3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afduplo.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afduplo2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afduplo4.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.afduplo5.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário Ferramentas Duplo</h3>

          <ul>
            <li>
              Material na chapa 26. Disponível na chapa 24 e 22 consulte valores
            </li>
            <li>Gavetas rolamento</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 874,<span>85</span>
          </p>
          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.af3gavetaspreto.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetaspreto2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetaspreto3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetaspreto4.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetaspreto5.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetascinza.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetascinza2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.af3gavetascinza3.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário de ferramenta 3 gavetas</h3>

          <ul>
            <li>
              Material na chapa 26. Disponível na chapa 24 e 22 consulte valores
            </li>
            <li>Gavetas rolamento</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 90 cm</li>
            <li>Profundidade: 40 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 1.068,<span>00</span>
          </p>
          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.afsimples.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário de ferramenta simples</h3>

          <ul>
            <li>
              Material na chapa 26. Disponível na chapa 24 e 22 consulte valores
            </li>
            <li>Gavetas rolamento</li>
            <li>Altura: 100 cm </li>
            <li>Largura: 48 cm</li>
            <li>Profundidade: 40 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 723,<span>00</span>
          </p>
          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default Products
